export default class Header {

    private _menu_toggle_element: HTMLElement;
    
    private _menu_open: boolean = false;
    private _scroll_disabled: boolean = false;
    private _scroll_top: number = 0;

    constructor() {

      this.init();

    }


    public updateLinks() {

        this._menu_toggle_element = document.querySelector( '.c-main-nav__toggle' );
        if ( this._menu_toggle_element ) {
    
            this._menu_toggle_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );
            // this._menu_toggle_element.click();
    
        }
    }


    private init() {

        this.updateLinks();

        window.addEventListener( 'closeMenu', ( event: Event) => this.closeMenu() );

        const hamburger_element: HTMLElement = document.querySelector( '.c-hamburger__link' );
        if ( hamburger_element ) {

            hamburger_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );

        }

        const search_modal_toggle: HTMLElement = document.querySelector('.search-toggle');

        if( search_modal_toggle ) {
            search_modal_toggle.addEventListener( 'click', this.searchModalToggle.bind(this) );
        }

    }

    private searchModalToggle( event: Event ) {
        event.preventDefault();

        this.closeMenu();

        setTimeout(() => {
            const event = new CustomEvent('openSearchModal');
            window.dispatchEvent(event);
        }, 300);
    }


    private hamburgerClicked( event: Event ) {

        event.preventDefault();

        this._menu_open = !this._menu_open;

        if ( this._menu_open) {

            this.disableScroll();
            document.body.classList.add( 's-menu-open' );
            
        } else {
            
            this.closeMenu();

        }


        return false;
        
    }


    private closeMenu() {

        this._menu_open = false;

        document.body.classList.remove( 's-menu-open' );
        
        this.enableScroll();

    } 


    private disableScroll() {
    
        if ( this._scroll_disabled ) return;
        
        this._scroll_top = window.pageYOffset || document.documentElement.scrollTop;
        document.body.style.top = `${-1 * this._scroll_top}px`;

        this._scroll_disabled = true;

    }


    private enableScroll() {

        if ( !this._scroll_disabled ) return;
    
        window.scrollTo( 0, this._scroll_top );
        document.body.style.top = '';
    
        this._scroll_disabled = false;

    }

}